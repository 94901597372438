<template>
  <div class="page-title" :class="border ? 'border' : ''">
    <Icon name="arrow-left" v-if="showArrow" size="18" class="back-icon" @click="back"/>

    {{title}}

    <img :src="moreUrl" alt="" v-if="showIcon"  class="more-icon" @click="detail"/>

 
  </div>
</template>
<script>
import { Popup, Icon, Button } from 'vant'
export default {
  name: 'pageHeader',
  components: {
    Popup,
    Icon,
    Button
  },
  data() {
    return {
      showPopup: false,
      loading: false
    }
  },
  props: {
    title: String,
    showIcon: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    
  },
  mounted() {
  },
  methods: {
    showRefresh() {
    
      if (!this.moreIcon) {
        return
      }
      this.showPopup = true
    },
    back() {
      this.$emit('back')
    },
    detail() {
      this.$emit('detail')
    },
   
  }
}
</script>